<template>
  <silo-form :edit="true" :silo="silo" v-if="silo != null" />
</template>

<script>
import { mapGetters } from "vuex";
import SiloForm from "./SiloForm.vue";

export default {
  name: "SiloCreate",

  components: {
    SiloForm,
  },

  data() {
    return {
      silo: null,
    };
  },

  computed: {
    ...mapGetters("silos", ["getSiloById"]),
  },

  created() {
    if (this.$route.params.id == undefined) this.$router.push("/silos");

    this.silo = this.getSiloById(this.$route.params.id);
  },
};
</script>